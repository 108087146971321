<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab title="브랜드 관리">
        <brand-list></brand-list>
      </b-tab>
      <b-tab title="브랜드 매핑">
        <brand-mapping v-bind="{tabIndex}"></brand-mapping>
      </b-tab>
      <b-tab title="수집제외 브랜드 관리">
        <brand-except></brand-except>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import brandList from '@/views/meta/BrandList.vue'
import brandMapping from '@/views/meta/BrandMapping.vue'
import brandExcept from '@/views/meta/BrandExcept.vue'

export default {
  name: "Brand",
  title: '브랜드 관리',
  components: {brandList, brandMapping, brandExcept},
  data() {
    return {
      tabIndex: 0,
    }
  }
}
</script>
