<template>
  <b-modal title="Brand XLSX Upload" size="xl" v-model="show" ok-only ok-title="닫기">
    <h5>
      Xlsx Upload
    </h5>

    <b-alert variant="success" show>
      <ul class="mb-0">
        <li>영문 브랜드명(brand_nm), 한글 브랜드명(brand_nm_kr)은 이미 존재하는 브랜드명 입력이 불가합니다.</li>
        <li>원산지(country_en)는 '원산지 분류' 메뉴에 등록된 영문 원산지를 입력해 주시기 바랍니다.</li>
        <li>좌측, 상단에 공백 없이 아래의 샘플과 같은 형태로 업로드해 주시기 바랍니다.</li>
      </ul>
    </b-alert>

    <b-tabs v-model="tabIndex">
      <b-tab title="Brand 대량등록">
        <htb ref="htb0" v-model="sampleData.brandBulk" :config="htbConfig.brandBulk" height="150px"></htb>
        <b-btn variant="success" @click="() => {$refs.xlsxBrandBulk.value = null; $refs.xlsxBrandBulk.click()}">Brand 대량등록 Xlsx Upload</b-btn>
        <b-btn class="ml-1" variant="outline-success" @click="templateXlsxDown">Brand 대량등록 Template Xlsx</b-btn>
        <input type="file" ref="xlsxBrandBulk" data-type="brandBulk" style="display: none" @change="handleXlsx">
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<style scoped>

</style>

<script>
import {down, readXlsx} from '@/shared/impexp'
import htb from '@/views/modules/HotTableBase.vue'

export default {
  name: 'UploadBrandXlsx',
  components: {htb},
  model: {prop: 'value', event: 'change'},
  props: {value: Boolean},
  data() {
    return {
      tabIndex: 0,
      busy: {brandBulk: false},
      sampleData: {
        brandBulk: [
          {brand_nm: 'HERMAN MILLER', brand_nm_kr: '허먼 밀러', brand_type: '홈/리빙', country_en: 'Argentina', use_yn: '사용'},
          {brand_nm: 'ABNORMALTHING', brand_nm_kr: '앱놀머씽', brand_type: '컨템포러리', country_en: 'Switzerland', use_yn: '미사용'},
        ],
      },
      validator: {
        brandBulk: {
          brand_nm: {test: /^.*$/, type: 'string', required: true},
          brand_nm_kr: {test: /^.*$/, type: 'string', required: true},
          brand_type: {test: new RegExp(`^(${this.$C.BRAND_TYPE.map(e => e.name).join('|').replace(/[/]/g, '\\$&')})$`), type: 'string', required: true},
          country_en: {test: /^[a-zA-Z \-']+$/, type: 'string', required: true},
          use_yn: {test: /^미?사용$/, type: 'string', required: true},
        },
      },
      htbConfig: {
        brandBulk: {
          fields: [
            {key: 'brand_nm'},
            {key: 'brand_nm_kr'},
            {key: 'brand_type'},
            {key: 'country_en'},
            {key: 'use_yn'},
          ]
        },
      }
    }
  },
  watch: {
    tabIndex(v) {
      setTimeout(() => this.$refs['htb' + v].hotInstance.render(), 0);
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    }
  },
  methods: {
    templateXlsxDown() {
      down([], null, Object.keys(this.validator.brandBulk), `Brand_대량등록_${this.$utils.dt()}`);
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');

      const {headers, rows} = await readXlsx(file);
      const type = event.target.dataset.type;
      this.uploadXlsx(event.target, headers, rows, type);
    },
    async uploadXlsx(target, headers, rows, type) {
      const vMap = this.validator[type];

      const unknown = headers.filter(e => !vMap[e]);
      if (unknown.length) return alert('알 수 없는 컬럼들이 있습니다:\n' + unknown.join('\n'));

      const required = Object.keys(vMap).filter(e => !headers.includes(e));
      if (required.length) return alert('필수 컬럼이 빠져있습니다:\n' + required.join('\n'));

      const wrongRows = [];
      rows.forEach((row, i) => {
        const wrongCols = [];
        headers.forEach(h => {
          const tester = vMap[h].test;
          if ((row[h] != null && row[h] !== '') && tester && !tester.test(row[h])) {
            wrongCols.push(`${h}: ${row[h]}`);
          }
          if ((row[h] == null || row[h] === '') && vMap[h].required) {
            wrongCols.push(`${h}: (비어있음)`);
          }
        });
        if (wrongCols.length) wrongRows.push({idx: i, cols: wrongCols});
      });
      if (wrongRows.length) return alert('다음 컬럼들의 값이 올바르지 않습니다:\n' + wrongRows.map(e => `${e.idx + 1} 번째줄 ${e.cols.map(e => e).join(', ')}`).join('\n'));

      // 컬럼 정의에 type 이 있는 경우 해당 타입으로 casting 한다.
      const typeHeaders = headers.filter(h => vMap[h].type);
      rows.forEach(row => {
        typeHeaders.forEach(h => {
          const type = vMap[h].type;
          if (type === 'number') {
            row[h] = +row[h];
          } else if (type === 'string') {
            row[h] = '' + row[h];
          } else if (type === 'boolean') {
            if (row[h] && typeof row[h] !== 'boolean') {
              if (row[h].match(/^true$/i)) {
                row[h] = true;
              } else if (row[h].match(/^false$/i)) {
                row[h] = false;
              } else {
                return alert('TRUE / FALSE 값이 맞게 입력되었는지 확인해주세요: ' + row[h]);
              }
            }
          }
        });
      });

      this.busy[type] = true;
      const j = await this.$api.postJson('/meta/brand/uploadBrand', {type, rows});
      this.busy[type] = false;
      if (j.ok === 1) {
        this.$utils.alert(`${j.cnt} 건 정상적으로 업로드 되었습니다`);
        this.$emit('refreshList');
        this.show = false;
      } else if (j.ok === -1) {
        this.$modal.show({title: '업로드 에러 확인', html: '<pre>' + `<h4>${j.msg}</h4>` + '</pre>'});
      }
      target.value = "";
    }
  }
}
</script>
