<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list()" v-focus></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="12">
        <brand-preset class="mb-2" v-model="form.list.brand"></brand-preset>
      </b-col>
    </b-row>
    <b-button @click="list()" class="mt-1 mr-1" variant="primary">검색</b-button>

    <div class="mt-3 mb-2 clearfix">
      <div class="pull-right">
        <b-button @click="showDownModal()" class="mr-1" variant="success">매핑 Xlsx Down</b-button>
        <b-button @click="showUpModal()" class="mr-1" variant="outline-success" :disabled="!$R('META_BRAND_W')"
                  :title="!$R('META_BRAND_W') ? 'META_BRAND_W 권한이 필요합니다' : ''">매핑 Xlsx Upload</b-button>
      </div>
      <b-button @click="removeSelected()" class="mr-1" variant="danger" :disabled="busy.remove || !$R('META_BRAND_W')"
                :title="!$R('META_BRAND_W') ? 'META_BRAND_W 권한이 필요합니다' : ''">
        선택삭제<b-spinner class="mr-1" small v-if="busy.remove"></b-spinner>
      </b-button>
    </div>

    <c-table id="brandMapping" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list"
             :caption="items.list.length + ' 개 매핑데이터'" @get-more="list(true)">
    </c-table>

    <b-modal title="브랜드 매핑 다운로드" size="xl" v-model="modal.down">
      <brand-preset v-model="form.down.brand"></brand-preset>
      <b-button @click="downXlsx()" class="mt-1 mr-1" variant="success">Down Xlsx</b-button>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <input type="file" ref="xlsx" style="display: none" @change="handleXlsx">
    <b-modal title="브랜드 매핑 업로드" size="xl" v-model="modal.up">
      <li>수집브랜드, 브랜드NO 만 인식해서 업로드 됩니다.</li>
      <li>수집브랜드 기준으로 새로운 값이나, 업데이트할 값을 입력하면 됩니다.</li>
      <li>수집브랜드가 중복일 경우, 새로운 브랜드NO 값으로 업데이트 됩니다.</li>
      <div class="mt-2">
        <b-button @click="() => {$refs.xlsx.value = null;$refs.xlsx.click()}" variant="outline-success" :disabled="!$R('META_BRAND_W')">
          매핑 Xlsx 파일 선택
        </b-button>
      </div>

      <template v-slot:modal-footer="{cancel}">
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <iframe ref="file_frame" name="file_frame" style="width:1px;height:1px;visibility:hidden"></iframe>
    <form :action="$api.getHost() + '/meta/brand/mappingXlsx'" ref="file_form" method="POST" target="file_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>
  </div>
</template>
<script>
import {readXlsx} from '@/shared/impexp'

export default {
  name: 'BrandMapping',
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
          brand: [],
        }
      },
      form: {
        list: {
          search: '',
          brand: [],
        },
        down: {
          brand: [],
        }
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      busy: {list: false, listmore: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {down: false, up: false},
      perPage: 20,

      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'brand_name', label: '수집브랜드'},
          {key: 'matched_brand_no', label: '브랜드NO'},
          {key: 'brand_nm_org', label: 'BALAAN 브랜드(영문)'},
          {key: 'brand_nm_kr', label: 'BALAAN 브랜드(한글)'},
          {key: 'html1', label: '브랜드 사용여부', class: 'text-center'},
        ]
      },
    };
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    this.list();
  },
  methods: {
    async list(more) {
      const form = this.form.list;
      const brand = form.brand.map(e => e.value);
      await this.$api.postTable(this, '/meta/brand/mapping', {...form, brand}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      e.html1 = e.disabled ? `<span class="badge badge-danger">미사용</span>` : `<span class="badge badge-success">사용</span>`;
      e.disabled_str = e.disabled ? '미사용' : '사용';
      return e;
    },
    showDownModal() {
      this.form.down.brand.splice(0, this.form.down.brand.length);
      this.modal.down = true;
    },
    showUpModal() {
      this.modal.up = true;
    },
    async downXlsx() {
      this.$refs.json_data.value = JSON.stringify({brand_nos: this.form.down.brand.map(e => e.value)});
      this.$refs.file_form.submit();
    },
    async removeSelected() {
      const selected = this.items.list.filter(e => e.selected);
      if (selected.length === 0) return alert('삭제할 브랜드 매핑을 선택해 주시기 바랍니다.');
      if (!confirm(`${selected.length} 개의 브랜드 매핑을 정말로 삭제하시겠습니까?`)) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/meta/brand/removeMappingMany', {brand_names: selected.map(e => e.brand_name)});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');
      const {rows} = await readXlsx(file);
      const j = await this.$api.postJson('/meta/brand/updateMapping', {rows});
      if (j) {
        this.modal.up = false;
        this.$alertTop(`업로드 되었습니다. ${j.cnt} 개 데이터가 수정되었습니다.`);
        this.list();
      }
    },
  },
}
</script>
